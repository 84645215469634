import $api from "../http"

export default class NotificationsService {
    static async sendAll(message){
        return $api.post(`/notifications/sendall`, {message})
    }
    static async loadTypes(){
        return $api.get(`/notifications/loadtypes`)
    }
    static async createType(name,img){
        return $api.post(`/notifications/createtype`, {name,img})
    }
    static async removeType(id){
        return $api.post(`/notifications/removetype`, {id})
    }
    static async notifyNews(id){
        return $api.post(`/notifications/notifynews`, {id})
    }
    static async notifyPayslip(){
        return $api.get(`/notifications/notifypayslip`)
    }
    static async notifyMessage(user_to,user_from,message){
        return $api.post(`/notifications/notifymessage`,{user_to,user_from,message})
    }
    static async notifyProjects(message){
        return $api.post(`/notifications/notifyprojects`,{message})
    }

}